import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

export interface DashboardPageProps {
  children: ReactNode
  title: string
  subTitle?: string
  backButton?: { text: string; href: string }
  renderActions?: ReactNode
  className?: string
}

const Standard = ({
  children,
  title,
  subTitle,
  backButton,
  renderActions,
  className = ''
}: DashboardPageProps) => {
  return (
    <div className={`min-h-full w-full bg-white ${className}`}>
      {/* HEADER */}
      <div className='flex flex-col p-3'>
        {/* TITLE AND BACK BUTTON */}
        <div>
          {backButton && <Link to={backButton.href}>{backButton.text}</Link>}
          <h1 className='text-lg font-semibold'>{title}</h1>
          {subTitle && <h2>{subTitle}</h2>}
        </div>
        {/* ACTIONS */}
        <div>{renderActions}</div>
      </div>
      {/* CONTENT */}
      <main>{children}</main>
    </div>
  )
}

const Form = ({ children, className = '' }: DashboardPageProps) => {
  return (
    <div
      className={`m-auto w-full border border-gray-100 bg-white px-4 md:w-min
       md:rounded-lg md:py-3 md:px-8 md:shadow-xl 
      ${className}`}
    >
      {children}
    </div>
  )
}

export const DashboardPages = { Standard, Form }
