import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const SettingsIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Settings-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z'></path>
      <circle cx='12' cy='12' r='3'></circle>
    </Icon>
  )
}

export default SettingsIcon
