import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import cl from 'classnames'
import FieldLabel from '../../../label/label'
import { CheckCircleIcon } from '../../../..'

export interface RadioProps {
  options: { label: ReactNode; value: string | boolean | number }[]
  ownValue?: any
  value: any
  label: ReactNode
  onChange: (value: any) => void
  className?: string
  disabled?: boolean
  autoFocus?: boolean
  valid?: boolean
  required?: boolean
  help?: string
}

export const Radio: FunctionComponent<RadioProps> = ({
  label,
  options,
  value,
  onChange,
  className = '',
  autoFocus = false,
  disabled = false,
  valid = false,
  required = false,
  help
}) => {
  const labelStyles = cl([
    `select-none text-gray-700 cursor-pointer flex 
  flex-row items-center text-sm font-medium`,
    {
      ['text-opacity-50 cursor-not-allowed']: disabled
    },
    className
  ])

  const radioButtonStyle = cl([
    `border rounded-full border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 
     focus-within:border-blue-500 cursor-pointer`
  ])

  return (
    <fieldset>
      <div>
        <FieldLabel
          htmlFor={null}
          className='flex w-full items-center justify-between'
        >
          <span className='flex items-center'>
            {label}{' '}
            {valid && (
              <CheckCircleIcon className='ml-1 h-3 w-3 text-blue-500' />
            )}
          </span>{' '}
          {required && (
            <Fragment>
              <span className=' hidden font-normal text-gray-400 sm:block'>
                Påkrævet
              </span>
              <span className='text-gray-400 sm:hidden'>*</span>
            </Fragment>
          )}
        </FieldLabel>
        {help && <p className='text-sm leading-5 text-gray-500'>{help}</p>}
      </div>
      <div className='mt-4 space-y-4'>
        {options.map((option) => {
          return (
            <label
              className={labelStyles}
              key={option.value.toString()}
              onClick={(e: any) => {
                if (option.value === value) {
                  e.preventDefault()
                  onChange(null)
                }
              }}
            >
              <input
                type='radio'
                value={boolOrNumberToString(option.value)}
                checked={option.value === value}
                onChange={() =>
                  onChange(option.value === value ? null : option.value)
                }
                className={`absolute h-3 w-3 cursor-pointer opacity-0`}
                autoFocus={autoFocus}
                disabled={disabled}
              />
              <div className={radioButtonStyle}>
                <svg
                  className='pointer-events-none hidden h-3 w-3 fill-current '
                  version='1.1'
                  viewBox='0 0 17 12'
                  xmlns='http://www.w3.org/2000/svg'
                  strokeWidth='1px'
                >
                  <g fill='none' fillRule='evenodd'>
                    <g
                      transform='translate(-9 -11)'
                      fill='#1F73F1'
                      fillRule='nonzero'
                    >
                      <path d='m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z' />
                    </g>
                  </g>
                </svg>
              </div>
              {option.label}
            </label>
          )
        })}
      </div>
    </fieldset>
  )
}

const boolOrNumberToString = (
  boolStringOrNumber: boolean | string | number
): string => {
  if (typeof boolStringOrNumber === 'string') return boolStringOrNumber
  return boolStringOrNumber ? 'true' : 'false'
}
