import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const InfoIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Info-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <circle cx='12' cy='12' r='10'></circle>
      <line x1='12' y1='16' x2='12' y2='12'></line>
      <line x1='12' y1='8' x2='12.01' y2='8'></line>
    </Icon>
  )
}

export default InfoIcon
