import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ChevronDownIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'ChevronDown-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='6 9 12 15 18 9'></polyline>
    </Icon>
  )
}

export default ChevronDownIcon
