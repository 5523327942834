import React, { ReactNode, FunctionComponent as FC } from 'react'

export interface IIcon {
  className?: string
  description?: string
  strokeWidth?: number
  onClick?: () => void
}

interface IGenericIcon {
  children: ReactNode
  className?: string
  description: string
  strokeWidth?: number
  onClick?: () => void
}

const Icon: FC<IGenericIcon> = ({
  children,
  className = '',
  description,
  strokeWidth = 2,
  onClick
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
      aria-label={description}
      onClick={onClick}
    >
      {children}
    </svg>
  )
}

export default Icon
