import React, { FunctionComponent, ReactNode } from 'react'
import { Listbox } from '@headlessui/react'
import cl from 'classnames'

export interface SelectProps {
  options: SelectOption[]
  selected: SelectOption | null
  onChange: (value: SelectOption) => void
  id: string
  /* If true, null option will be rendered */
  placeholder?: string
  className?: string
  invalid?: boolean
  disabled?: boolean
  /* If true, null option will not be selectable */
  required?: boolean
  autoFocus?: boolean
  short?: boolean
}

export interface SelectOption {
  label: ReactNode
  value: string | number
  disabled?: boolean
}

export const Select: FunctionComponent<SelectProps> = ({
  options,
  selected = null,
  onChange,
  className = '',
  id,
  placeholder = 'Vælg',
  disabled,
  required = false,
  invalid,
  autoFocus = false,
  short = false
}) => {
  const buttonStyles = cl(
    `border border-gray-300 rounded py-2 px-4 bg-white   
    focus-within:border-blue-500 group-hover:border-blue-500 focus:outline-none 
    w-full inline-flex items-center truncate text-sm`,
    {
      [`hover-within:border-blue-500`]: !disabled,
      [`cursor-not-allowed bg-gray-200`]: disabled,
      [`border-red-500`]: invalid,
      [`md:w-96`]: !short,
      [`md:w-60`]: short
    },
    `${className}`
  )

  const optionsStyles = cl(
    `absolute mt-1 group-hover:border-blue-500 rounded overflow-auto bg-white  
    max-h-40 border border-gray-300 focus:outline-none text-sm z-20 shadow-lg hover:border-blue-500`,
    { [`md:w-96`]: !short, [`md:w-60`]: short }
  )

  const optionStyles = (
    active: boolean,
    selected: boolean,
    optionDisabled?: boolean
  ) =>
    cl(
      `cursor-pointer select-none text-sm relative py-2 px-4 truncate`,
      `flex items-center`,
      {
        [`text-blue-600`]: selected && !active && !optionDisabled,
        [`font-medium`]: selected,
        [`bg-blue-500 text-white`]: active,
        [`text-gray-400 cursor-not-allowed opacity-50`]: optionDisabled
      }
    )

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled}>
      <div className='group relative mt-1'>
        <Listbox.Button
          className={buttonStyles}
          id={id}
          disabled={disabled}
          autoFocus={autoFocus}
        >
          <span className='truncate' style={{ maxWidth: '15rem' }}>
            {selected && selected.label}
            {!selected && <span className='text-gray-400'>{placeholder}</span>}
          </span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='ml-auto h-4 w-4 text-gray-700 '
          >
            <path d='M7 15l5 5 5-5'></path>
            <path d='M7 9l5-5 5 5'></path>
          </svg>
        </Listbox.Button>
        <Listbox.Options className={optionsStyles}>
          {placeholder && (
            <Listbox.Option
              key={null}
              value={null}
              disabled={required}
              className={({ active }) =>
                optionStyles(active, selected === null, required)
              }
            >
              <span className={'truncate'} style={{ maxWidth: '12rem' }}>
                {placeholder}
              </span>
            </Listbox.Option>
          )}
          {options.map((option) => (
            <Listbox.Option
              key={`${option.value} ${option.label}`}
              value={option}
              disabled={option.disabled}
              className={({ active }) =>
                optionStyles(
                  active,
                  (selected && selected.value) == option.value,
                  option.disabled
                )
              }
            >
              {selected?.value === option.value && (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className={`mr-4 h-4 w-4`}
                >
                  <polyline points='20 6 9 17 4 12'></polyline>
                </svg>
              )}
              <span className={'truncate'} style={{ maxWidth: '12rem' }}>
                {option.label}
              </span>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default Select
