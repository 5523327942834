import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ChevronRightIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'ChevronRight-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='9 18 15 12 9 6'></polyline>
    </Icon>
  )
}

export default ChevronRightIcon
