import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const TrendingupIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Trendingup-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='23 6 13.5 15.5 8.5 10.5 1 18'></polyline>
      <polyline points='17 6 23 6 23 12'></polyline>
    </Icon>
  )
}

export default TrendingupIcon
