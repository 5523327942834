import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, ReactNode } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { classNames } from '../../../helpers/classnames'
import ChevronDownIcon from '../../icons/ChevronDown'
import { PassableReactComponent } from '../../../typings'

export type Action = {
  text: ReactNode
  icon?: PassableReactComponent
  hideWhen?: 'mobile' | 'always'
  style?: 'action'
} & (
  | {
      type: 'LINK'
      href: string
    }
  | {
      type: 'ONCLICK'
      onClick: () => void
    }
)

export interface ActionGroupProps {
  actions: Action[]
}

export const ActionGroup = ({ actions }: ActionGroupProps) => {
  const alwaysInMenu = actions.filter((a) => a.hideWhen === 'always')
  const inMenuMobile = actions.filter((a) => a.hideWhen === 'mobile')
  const neverHide = actions.filter((a) => a.hideWhen === undefined)

  const hasDesktopMenu = alwaysInMenu.length > 0
  const hasMobileMenu = alwaysInMenu.length + inMenuMobile.length > 0

  return (
    <Fragment>
      {[...neverHide, ...inMenuMobile].map(renderActionButton)}

      {/* Desktop Dropdown */}
      {hasDesktopMenu && (
        <Menu
          as='span'
          className={`relative ${
            neverHide.length !== 0 && 'ml-3'
          } hidden sm:flex`}
        >
          <Menu.Button className='focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
            {neverHide.length !== 0 ? 'Mere' : 'Menu'}
            <ChevronDownIcon
              className='-mr-1 ml-2 h-5 w-5 text-gray-500'
              aria-hidden='true'
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='focus:outline-none absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5'>
              {alwaysInMenu.map(renderActionMenuButton)}
            </Menu.Items>
          </Transition>
        </Menu>
      )}

      {/* Mobile Dropdown */}
      {hasMobileMenu && (
        <Menu
          as='span'
          className={`relative ${neverHide.length !== 0 && 'ml-3'} sm:hidden`}
        >
          <Menu.Button className='focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
            {neverHide.length !== 0 ? 'Mere' : 'Menu'}
            <ChevronDownIcon
              className='-mr-1 ml-2 h-5 w-5 text-gray-500'
              aria-hidden='true'
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={`focus:outline-none absolute z-10 ${
                neverHide.length > 0
                  ? 'right-0 origin-top-right'
                  : 'left-0 origin-top-left'
              } mt-2 -mr-1 w-48 ${
                alwaysInMenu.length > 0 &&
                inMenuMobile.length > 0 &&
                'divide-y divide-gray-100'
              } rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5`}
            >
              {alwaysInMenu.length > 0 && (
                <div className='py-1'>
                  {alwaysInMenu.map(renderActionMenuButton)}
                </div>
              )}
              {inMenuMobile.length > 0 && (
                <div className='py-1'>
                  {inMenuMobile.map(renderActionMenuButton)}
                </div>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </Fragment>
  )
}

const renderActionButton = (action: Action, idx: number) =>
  action.type === 'LINK' ? (
    <span
      key={idx}
      className={`${action.hideWhen === 'mobile' && 'hidden'} sm:block ${
        idx !== 0 && 'ml-3'
      }`}
    >
      <NavLink
        type='button'
        className={getButtonStyles(action)}
        to={action.href}
      >
        {action.icon && (
          <action.icon
            className={`-ml-1 mr-2 h-5 w-5 ${
              action.style === 'action' ? 'text-white' : 'text-gray-500'
            }`}
            aria-hidden='true'
          />
        )}
        {action.text}
      </NavLink>
    </span>
  ) : (
    <span
      key={idx}
      className={`${action.hideWhen === 'mobile' && 'hidden'} sm:block ${
        idx !== 0 && 'ml-3'
      }`}
    >
      <button
        onClick={action.onClick}
        type='button'
        className={getButtonStyles(action)}
      >
        {action.icon && (
          <action.icon
            className='-ml-1 mr-2 h-5 w-5 text-gray-500'
            aria-hidden='true'
          />
        )}
        {action.text}
      </button>
    </span>
  )

const getButtonStyles = (action: Action) =>
  action.style === 'action'
    ? 'focus:outline-none inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600'
    : 'focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'

const renderActionMenuButton = (action: Action, idx: number) =>
  action.type === 'LINK' ? (
    <Menu.Item key={idx}>
      {({ active }) => (
        <Link
          to={action.href}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'group flex items-center px-4 py-2 text-sm'
          )}
        >
          {action.icon && (
            <action.icon
              className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
              aria-hidden='true'
            />
          )}
          {action.text}
        </Link>
      )}
    </Menu.Item>
  ) : (
    <Menu.Item key={idx}>
      {({ active }) => (
        <button
          onClick={action.onClick}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'group flex items-center px-4 py-2 text-sm'
          )}
        >
          {action.icon && (
            <action.icon
              className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
              aria-hidden='true'
            />
          )}
          {action.text}
        </button>
      )}
    </Menu.Item>
  )
