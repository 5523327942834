import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ZapIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Lynikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polygon points='13 2 3 14 12 14 11 22 21 10 12 10 13 2'></polygon>
    </Icon>
  )
}

export default ZapIcon
