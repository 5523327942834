import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const PlusIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Plus-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <line x1='12' y1='5' x2='12' y2='19'></line>
      <line x1='5' y1='12' x2='19' y2='12'></line>
    </Icon>
  )
}

export default PlusIcon
