import React, { FunctionComponent as FC, ReactNode } from 'react'
import FieldLabel from '../../label/label'

export interface UploadProps {
  label: ReactNode
  files: File[]
  setFiles: Function
}

export const Upload: FC<UploadProps> = ({ files, setFiles, label }) => {
  const numberOfFiles = Array.from(files).length
  return (
    <fieldset className='w-full md:w-96'>
      <FieldLabel
        htmlFor={'file' + label}
        className='flex flex-row items-center'
      >
        {label}
        {numberOfFiles > 0 && (
          <span className={`ml-auto text-xs uppercase text-gray-600`}>
            {numberOfFiles} {numberOfFiles === 1 ? 'fil' : 'filer'} valgt
          </span>
        )}
      </FieldLabel>

      {(files === null || files.length === 0) && (
        <label
          htmlFor={'file' + label}
          className={`inline-flex w-full transform cursor-pointer items-center justify-center rounded bg-gray-100 
          py-2 px-6 text-center font-medium 
          text-gray-900 transition hover:translate-y-px
          hover:bg-gray-200 active:translate-y-0.5 md:w-max
          `}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10'
            height='10'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='mr-2 h-4 w-4 text-gray-700'
          >
            <path d='M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4'></path>
            <polyline points='17 8 12 3 7 8'></polyline>
            <line x1='12' y1='3' x2='12' y2='15'></line>
          </svg>
          Vælg fil
        </label>
      )}

      {/* RENDER CHOSEN FILES */}
      {numberOfFiles > 0 && (
        <div className={`mt-1 w-full overflow-auto text-base `}>
          {Array.from(files).map((file, i) => {
            return (
              <div
                key={i}
                className={`group mt-3 flex select-none flex-row items-center truncate 
                rounded border border-gray-200 bg-gray-100 p-3 py-3
               px-4 text-sm`}
              >
                <span className={`mr-2 text-gray-400`}>
                  {renderIcon(file.name)}
                </span>
                <span>{formatFileName(file.name)}</span>
                <span
                  className='ml-auto cursor-pointer'
                  onClick={() => {
                    const nextFiles = Array.from(files).filter(
                      (_, index) => index !== i
                    )
                    setFiles(nextFiles)
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='h-4 w-4 text-gray-600 hover:text-gray-800'
                  >
                    <polyline points='3 6 5 6 21 6'></polyline>
                    <path d='M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2'></path>
                    <line x1='10' y1='11' x2='10' y2='17'></line>
                    <line x1='14' y1='11' x2='14' y2='17'></line>
                  </svg>
                </span>
              </div>
            )
          })}
        </div>
      )}

      {files !== null && files.length > 0 && (
        <label
          className={`mt-2 inline-flex w-full transform cursor-pointer items-center justify-center 
          py-2 px-4 text-center  text-sm
          font-medium text-gray-700 transition hover:translate-y-px
           hover:text-gray-800 active:translate-y-0.5 md:w-max`}
          htmlFor={'file' + label}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            className={`mr-4 h-4 w-4`}
          >
            <path d='M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4'></path>
            <polyline points='17 8 12 3 7 8'></polyline>
            <line x1='12' y1='3' x2='12' y2='15'></line>
          </svg>
          Tilføj flere filer
        </label>
      )}

      <input
        className='hidden'
        multiple={true}
        onChange={(e) => {
          if (e.target.files !== null) {
            const nextFiles =
              files === null
                ? Array.from(e.target.files)
                : [...Array.from(files), ...Array.from(e.target.files)]
            setFiles(nextFiles)
          }
        }}
        type='file'
        name='file'
        id={'file' + label}
      />
    </fieldset>
  )
}

const SVG: FC<{ children: ReactNode }> = ({ children }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={`h-5 w-5 text-blue-600`}
  >
    {children}
  </svg>
)

function renderIcon(fileName: string) {
  const extension = fileName.split('.')[fileName.split('.').length - 1]
  switch (extension) {
    case 'pdf':
      return (
        <SVG>
          <path d='M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z'></path>
          <polyline points='14 2 14 8 20 8'></polyline>
          <line x1='16' y1='13' x2='8' y2='13'></line>
          <line x1='16' y1='17' x2='8' y2='17'></line>
          <line x1='10' y1='9' x2='8' y2='9'></line>
        </SVG>
      )
    case 'txt':
      return (
        <SVG>
          <path d='M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z'></path>
          <polyline points='14 2 14 8 20 8'></polyline>
          <line x1='16' y1='13' x2='8' y2='13'></line>
          <line x1='16' y1='17' x2='8' y2='17'></line>
          <line x1='10' y1='9' x2='8' y2='9'></line>
        </SVG>
      )
    case 'png':
      return get_img_svg()
    case 'jpg':
      return get_img_svg()
    case 'jpeg':
      return get_img_svg()
    case 'bmp':
      return get_img_svg()
    default:
      return (
        <SVG>
          <path d='M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z'></path>
          <polyline points='14 2 14 8 20 8'></polyline>
        </SVG>
      )
  }
}

function get_img_svg() {
  return (
    <SVG>
      <rect x='3' y='3' width='18' height='18' rx='2' ry='2'></rect>
      <circle cx='8.5' cy='8.5' r='1.5'></circle>
      <polyline points='21 15 16 10 5 21'></polyline>
    </SVG>
  )
}

function formatFileName(fileName: string) {
  const fnl = fileName.length
  if (fnl < 26) return fileName

  return `${fileName.substr(0, 13)}...${fileName.substr(fnl - 13)}`
}

export default Upload
