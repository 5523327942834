import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const PointerIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Pointer-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M22 14a8 8 0 01-8 8'></path>
      <path d='M18 11v-1a2 2 0 00-2-2v0a2 2 0 00-2 2v0'></path>
      <path d='M14 10V9a2 2 0 00-2-2v0a2 2 0 00-2 2v1'></path>
      <path d='M10 9.5V4a2 2 0 00-2-2v0a2 2 0 00-2 2v10'></path>
      <path d='M18 11a2 2 0 114 0v3a8 8 0 01-8 8h-2c-2.8 0-4.5-.86-5.99-2.34l-3.6-3.6a2 2 0 012.83-2.82L7 15'></path>
    </Icon>
  )
}

export default PointerIcon
