import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const FilterIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Filter-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polygon points='22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3'></polygon>
    </Icon>
  )
}

export default FilterIcon
