import React, { FunctionComponent as FC, ReactNode } from 'react'

interface FieldLabelProps {
  htmlFor: string | null
  className?: string
  children: ReactNode
}

const FieldLabel: FC<FieldLabelProps> = ({ htmlFor, className, children }) => {
  return (
    <label
      htmlFor={htmlFor ? htmlFor : ''}
      className={`block text-sm font-medium text-gray-700 ${className}`}
    >
      {children}
    </label>
  )
}

export default FieldLabel
