import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ShieldIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'skjold-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
    </Icon>
  )
}

export default ShieldIcon
