import React, { ReactNode } from 'react'

export interface FormSectionprops {
  children: ReactNode
  className?: string
}

export const FormHeading = ({ children, className = '' }: FormSectionprops) => {
  return (
    <h1
      className={`flex flex-row items-center text-2xl font-bold text-blue-900 ${className}`}
    >
      {children}
    </h1>
  )
}
