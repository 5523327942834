import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const UsersIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Users-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2'></path>
      <circle cx='9' cy='7' r='4'></circle>
      <path d='M23 21v-2a4 4 0 00-3-3.87'></path>
      <path d='M16 3.13a4 4 0 010 7.75'></path>
    </Icon>
  )
}

export default UsersIcon
