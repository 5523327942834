import React, { FunctionComponent as FC } from 'react'
import cl from 'classnames'
import { PassableReactComponent } from '../../typings'

export interface ButtonProps {
  children: React.ReactNode
  onClick: () => void
  type?: 'primary' | 'danger'
  className?: string
  disabled?: boolean
  loading?: boolean
  count?: number
  icon?: { leading?: PassableReactComponent; trailing?: PassableReactComponent }
  htmlType?: 'button' | 'reset' | 'submit'
}

export const Button: FC<ButtonProps> = ({
  children,
  onClick,
  type,
  className,
  disabled = false,
  loading = false,
  count,
  htmlType = 'button',
  icon
}) => {
  const classNames = cl(
    `inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 relative`,
    {
      ['text-gray-700 bg-white hover:bg-gray-50 focus:ring-blue-500 border-gray-300']:
        !type, // white
      ['text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-600 a']:
        type === 'primary',
      ['text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400 b']:
        type === 'danger',
      'animate-pulse cursor-wait': loading === true,
      'opacity-50 cursor-not-allowed': disabled
    },
    className
  )

  const countClassNames = cl(
    `absolute -top-1.5 -right-1.5 flex h-4 w-4 items-center shadow-lg  
  justify-center rounded-full border border-white text-xs font-medium shadow-lg`,
    {
      'bg-white border-gray-300': !type,
      'bg-blue-700 ring-1 ring-blue-700': type === 'primary',
      'bg-yellow-500 ring-1 ring-yellow-500': type === 'danger'
    }
  )

  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames}
      type={htmlType}
    >
      {loading === true && <LoadingIcon />}
      {!loading && icon?.leading && (
        <icon.leading
          className='-ml-1 mr-2 h-5 w-5'
          strokeWidth={1.5}
          aria-hidden='true'
        />
      )}
      {children}
      {!loading && icon?.trailing && (
        <icon.trailing
          className='ml-2 -mr-1 h-5 w-5'
          strokeWidth={1.5}
          aria-hidden='true'
        />
      )}
      {count && (
        <div className={countClassNames}>
          <span className='z-10'>{count}</span>
        </div>
      )}
    </button>
  )
}

const LoadingIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    className='-ml-1 mr-2 h-5 w-5 animate-spin'
  >
    <circle
      className='opacity-25'
      cx='12'
      cy='12'
      r='10'
      stroke='currentColor'
      strokeWidth='4'
    ></circle>
    <path
      className='opacity-75'
      fill='currentColor'
      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
    ></path>
  </svg>
)
