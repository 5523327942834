import React, { ReactNode, FunctionComponent as FC, Fragment } from 'react'
// import cl from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import XIcon from '../icons/X'

export interface IModal {
  children: ReactNode
  className?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  padded?: boolean
}

export const Modal: FC<IModal> = ({
  children,
  className = '',
  isOpen,
  setIsOpen,
  padded = true
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-10 overflow-y-auto'
        onClose={() => setIsOpen(false)}
      >
        <div className='min-h-screen px-2 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 cursor-pointer bg-gray-300 bg-opacity-50 backdrop-blur-sm backdrop-filter transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='mt-48 inline-block h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-400 transform'
            enterFrom='-translate-y-30 opacity-0 scale-95'
            enterTo='translate-y-0 opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='translate-y-0 opacity-100 scale-100'
            leaveTo='translate-y-30 opacity-0 scale-95'
          >
            <div
              className={`relative z-20 inline-block w-full max-w-md 
              transform overflow-hidden rounded-lg border border-gray-200 
              bg-white text-left align-middle shadow-xl transition-all  
              ${padded ? 'my-8 p-6 md:p-8' : ''}
             ${className}`}
            >
              {padded && (
                <div
                  onClick={() => setIsOpen(false)}
                  className='absolute top-2 right-2 cursor-pointer text-gray-400 transition hover:text-gray-700'
                >
                  <XIcon className='w-6' />
                </div>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
