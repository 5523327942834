import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const SortAscIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'SortAsc-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M11 5h4'></path>
      <path d='M11 9h7'></path>
      <path d='M11 13h10'></path>
      <path d='m3 17 3 3 3-3'></path>
      <path d='M6 18V4'></path>
    </Icon>
  )
}

export default SortAscIcon
