import React, {
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  ReactNode,
  Fragment
} from 'react'
import { Transition } from '@headlessui/react'
import cl from 'classnames'
import CheckCircleIcon from '../icons/CheckCircle'
import AlertTriangleIcon from '../icons/AlertTriangle'
import AlertCircleIcon from '../icons/AlertCircle'
import XIcon from '../icons/X'

export interface Notification {
  heading: ReactNode
  text?: ReactNode
  type?: 'alert' | 'failure'
  id?: string
  duration?: number
}

export interface NotificationProps extends Notification {
  className?: string
}

export const NotificationCard = ({
  heading,
  text,
  type,
  duration = 5000
}: NotificationProps) => {
  const [shown, setShown] = useState(true)

  const hide = () => setShown(false)

  useTimeout(hide, duration)

  const iconStyle = cl([
    `h-5 w-5 mt-0.5`,
    {
      ['text-blue-600']: !type,
      ['text-yellow-500']: type === 'alert',
      ['text-red-600']: type === 'failure'
    }
  ])

  const icon = !type ? (
    <CheckCircleIcon className={iconStyle} aria-hidden='true' strokeWidth={2} />
  ) : type === 'alert' ? (
    <AlertTriangleIcon
      className={iconStyle}
      aria-hidden='true'
      strokeWidth={2}
    />
  ) : (
    <AlertCircleIcon className={iconStyle} aria-hidden='true' strokeWidth={2} />
  )

  return (
    <Transition
      show={shown}
      as={Fragment}
      enter='transform ease-out duration-300 transition'
      enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
      enterTo='translate-y-0 opacity-100 sm:translate-x-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      appear
    >
      <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
        <div className='p-4'>
          <div className='flex items-start'>
            <div className='flex-shrink-0'>{icon}</div>
            <div className='ml-3 w-0 flex-1 pt-0.5'>
              <p className='text-sm font-medium text-gray-900'>{heading}</p>
              <p className='mt-1 text-sm text-gray-500'>{text}</p>
            </div>
            <div className='ml-4 flex flex-shrink-0'>
              <button
                className='focus:outline-none inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={hide}
              >
                <span className='sr-only'>Skjul</span>
                <XIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.

  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.

  useEffect(() => {
    // Don't schedule if no delay is specified.

    // Note: 0 is a valid value for delay.

    if (!delay && delay !== 0) {
      return
    }

    const id = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}
