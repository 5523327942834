import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const RulerIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Ruler-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M16 2l6 6L8 22l-6-6L16 2'></path>
      <path d='M7.5 10.5l2 2'></path>
      <path d='M10.5 7.5l2 2'></path>
      <path d='M13.5 4.5l2 2'></path>
      <path d='M4.5 13.5l2 2'></path>
    </Icon>
  )
}

export default RulerIcon
