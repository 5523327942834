import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const TruckIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Truck-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M10 17h4V5H2v12h3m15 0h2v-3.34a4 4 0 00-1.17-2.83L19 9h-5v8h1'></path>
      <circle cx='7.5' cy='17.5' r='2.5'></circle>
      <circle cx='17.5' cy='17.5' r='2.5'></circle>
    </Icon>
  )
}

export default TruckIcon
