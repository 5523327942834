import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const PlugZapIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'PlugZap-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M13 2l-2 2.5h3L12 7'></path>
      <path d='M12 22v-3'></path>
      <path d='M10 13v-2.5'></path>
      <path d='M10 12.5v-2'></path>
      <path d='M14 12.5v-2'></path>
      <path d='M16 15a2 2 0 00-2-2h-4a2 2 0 00-2 2v2a2 2 0 002 2h4a2 2 0 002-2v-2z'></path>
    </Icon>
  )
}

export default PlugZapIcon
