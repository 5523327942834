import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const SearchIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Search-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <circle cx='11' cy='11' r='8'></circle>
      <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
    </Icon>
  )
}

export default SearchIcon
