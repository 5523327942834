import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const RefreshcwIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Refreshcw-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M21 2v6h-6'></path>
      <path d='M3 12a9 9 0 0115-6.7L21 8'></path>
      <path d='M3 22v-6h6'></path>
      <path d='M21 12a9 9 0 01-15 6.7L3 16'></path>
    </Icon>
  )
}

export default RefreshcwIcon
