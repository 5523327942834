import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const DownloadIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Download-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4'></path>
      <polyline points='7 10 12 15 17 10'></polyline>
      <line x1='12' y1='15' x2='12' y2='3'></line>
    </Icon>
  )
}

export default DownloadIcon
