import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const HistoryIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'History-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M3 3v5h5'></path>
      <path d='M3.05 13A9 9 0 106 5.3L3 8'></path>
      <path d='M12 7v5l4 2'></path>
    </Icon>
  )
}

export default HistoryIcon
