import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const InboxIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Inbox-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='22 12 16 12 14 15 10 15 8 12 2 12'></polyline>
      <path d='M5.45 5.11L2 12v6a2 2 0 002 2h16a2 2 0 002-2v-6l-3.45-6.89A2 2 0 0016.76 4H7.24a2 2 0 00-1.79 1.11z'></path>
    </Icon>
  )
}

export default InboxIcon
