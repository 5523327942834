import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const UnlockIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Unlock-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <rect x='3' y='11' width='18' height='11' rx='2' ry='2'></rect>
      <path d='M7 11V7a5 5 0 019.9-1'></path>
    </Icon>
  )
}

export default UnlockIcon
