import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const FileX2Icon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'FileX2-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M4 22h14a2 2 0 002-2V7.5L14.5 2H6a2 2 0 00-2 2v4'></path>
      <path d='M14 2v6h6'></path>
      <path d='M3 12.5l5 5'></path>
      <path d='M8 12.5l-5 5'></path>
    </Icon>
  )
}

export default FileX2Icon
