import React, { ReactNode } from 'react'

export const Container = ({
  children,
  className
}: {
  children: ReactNode
  className: string
}) => (
  <div
    className={`p-4 sm:p-8 lg:flex lg:items-center lg:justify-between ${className}`}
  >
    {children}
  </div>
)
