import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ArrowUpIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'ArrowUp-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <line x1='12' y1='19' x2='12' y2='5'></line>
      <polyline points='5 12 12 5 19 12'></polyline>
    </Icon>
  )
}

export default ArrowUpIcon
