/* This example requires Tailwind CSS v2.0+ */
import React, { Component, Fragment, FunctionComponent, ReactNode } from 'react'
import da from 'date-fns/esm/locale/da/index.js'
import { endOfDay, format, formatDistanceToNow, isAfter } from 'date-fns'

export interface Day {
  date: Date
  icon: typeof Component | FunctionComponent
  events: ReactNode[]
  color?: string
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export interface MultiEventTimelineProps {
  days: Day[]
  dateFormat?: string
  dayOfTheWeek?: boolean
  distanceToNow?: boolean
  className?: string
}

export function MultiEventTimeline({
  days,
  dateFormat = 'd. MMMM',
  dayOfTheWeek = false,
  distanceToNow = false,
  className = ''
}: MultiEventTimelineProps) {
  return (
    <div className={`flow-root ${className}`}>
      <ul role='list' className='-mb-8'>
        {days
          .sort((a, b) => (a.date > b.date ? 1 : -1))
          .map((day, dayIdx) => {
            const isNotLast = dayIdx !== days.length - 1
            return (
              <li key={day.date.toString() + dayIdx}>
                <div className={`relative ${isNotLast ? 'pb-6' : 'pb-2'}`}>
                  {/* LINE */}
                  <span
                    className='absolute top-4 left-3 -ml-px h-full w-0.5 bg-gray-200 md:left-4'
                    aria-hidden='true'
                  />
                  <div className='relative flex space-x-3'>
                    {/* ICON */}
                    <div>
                      <span
                        className={classNames(
                          day.color ?? 'bg-blue-900',
                          'flex h-6 w-6 items-center justify-center rounded-full ring-8 ring-white md:h-8 md:w-8'
                        )}
                      >
                        <day.icon
                          className='h-4 w-4 text-white md:h-5 md:w-5'
                          aria-hidden='true'
                          strokeWidth={2}
                        />
                      </span>
                    </div>

                    {/* DATE TEXT */}
                    <div className='flex min-w-0 flex-1 flex-col justify-between space-y-4 pt-1.5'>
                      <div>
                        <p className='text-xs text-gray-500 md:text-sm'>
                          <time
                            dateTime={day.date.toString()}
                            className='flex justify-between'
                          >
                            {dayOfTheWeek && (
                              <span className='capitalize'>
                                {format(day.date, 'eeee', {
                                  locale: da
                                })}
                              </span>
                            )}
                            {distanceToNow && (
                              <span className=''>
                                {isAfter(new Date(), endOfDay(day.date))
                                  ? 'for'
                                  : ''}{' '}
                                {formatDistanceToNow(endOfDay(day.date), {
                                  locale: da,
                                  addSuffix: true
                                })}
                              </span>
                            )}
                            <span>
                              {format(day.date, dateFormat, { locale: da })}
                            </span>
                          </time>
                        </p>
                      </div>

                      {day.events.map((e, i) => (
                        <Fragment key={i}>{e}</Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
