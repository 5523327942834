import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ChevronLeftIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'ChevronLeft-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='15 18 9 12 15 6'></polyline>
    </Icon>
  )
}

export default ChevronLeftIcon
