import React, {
  Component,
  forwardRef,
  Fragment,
  FunctionComponent,
  ReactChild
} from 'react'
import { CheckCircleIcon } from '../../../..'
import FieldLabel from '../../../label/label'
import Input, { InputProps } from '.'

export interface TextFieldsetProps extends InputProps {
  label: string
  help?: string | ReactChild
  error?: string | ReactChild | null | boolean
  inputClassName?: string
  valid?: boolean
  required?: boolean
  short?: boolean
  Icon?: typeof Component | FunctionComponent
}

export const TextFieldset = forwardRef<HTMLInputElement, TextFieldsetProps>(
  ({
    label,
    help = null,
    error = null,
    value,
    onChange,
    className = '',
    inputClassName = '',
    id,
    type = 'text',
    invalid = false,
    disabled = false,
    placeholder,
    rows = 1,
    autoFocus,
    autoComplete,
    valid = false,
    required = false,
    short = false,
    Icon,
    clearable = false
  }) => {
    return (
      <div className={`w-full space-y-1.5 md:w-min ${className}`}>
        <FieldLabel
          htmlFor={id}
          className='flex w-full items-center justify-between'
        >
          <span className='flex items-center'>
            {label}{' '}
            {valid && (
              <CheckCircleIcon className='ml-1 h-3 w-3 text-blue-500' />
            )}
          </span>{' '}
          {required && (
            <Fragment>
              <span className=' hidden font-normal text-gray-400 sm:block'>
                Påkrævet
              </span>
              <span className='text-gray-400 sm:hidden'>*</span>
            </Fragment>
          )}
        </FieldLabel>
        <Input
          id={id}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          invalid={invalid}
          value={value}
          onChange={onChange}
          className={inputClassName}
          rows={rows}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          short={short}
          Icon={Icon}
          clearable={clearable}
        />
        {help && <span className='block text-sm text-gray-500'>{help}</span>}
        {error && <span className='block text-sm text-red-600'>{error}</span>}
      </div>
    )
  }
)
