import React, { useState, ReactChild, useEffect, Fragment } from 'react'
import { CheckCircleIcon, Edit2Icon, FieldLabel } from '../../..'
import PointerIcon from '../../icons/Pointer'
import Input, { InputProps } from './text'
import Select, { SelectOption } from './select/select'

export interface TextOrSelectFieldsetProps extends InputProps {
  id: string
  label: string
  help?: string | ReactChild
  error?: string | ReactChild | null | boolean
  inputClassName?: string
  valid?: boolean
  required?: boolean
  short?: boolean
  options: SelectOption[]
  className?: string
  entity?: string
  preferSelect?: boolean
  initialValue?: SelectOption
}

export const TextOrSelectFieldset = ({
  id,
  label,
  valid,
  help,
  error,
  required,
  className = '',
  options,
  entity = '',
  placeholder,
  autoComplete,
  autoFocus,
  invalid,
  disabled,
  rows,
  short,
  Icon,
  type,
  inputClassName,
  onChange,
  preferSelect = false,
  initialValue
}: TextOrSelectFieldsetProps) => {
  const [writeValue, setWriteValue] = useState<string>('')
  const [selectValue, setSelectValue] = useState<SelectOption | null>(null)
  const [writeNew, setWriteNew] = useState<boolean>(
    preferSelect && options.length > 0 ? false : true
  )

  useEffect(() => {
    if (initialValue) {
      setWriteNew(false)
      setSelectValue(initialValue)
    }
  }, [])

  useEffect(() => {
    if (writeNew) {
      onChange(writeValue)
    } else if (selectValue !== null) {
      onChange(selectValue?.value)
    }
  }, [writeNew, writeValue, selectValue])

  return (
    <div className={`w-full space-y-1.5 md:w-min ${className}`}>
      <FieldLabel
        htmlFor={id}
        className='flex w-full items-center justify-between'
      >
        <span className='flex items-center'>
          {label}{' '}
          {valid && <CheckCircleIcon className='ml-1 h-3 w-3 text-blue-500' />}
        </span>{' '}
        {required && (
          <Fragment>
            <span className=' hidden font-normal text-gray-400 sm:block'>
              Påkrævet
            </span>
            <span className='text-gray-400 sm:hidden'>*</span>
          </Fragment>
        )}
      </FieldLabel>

      {writeNew && (
        <Input
          id={id}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          invalid={invalid}
          value={writeValue}
          onChange={(c) => {
            setWriteValue(c)
            setSelectValue(null)
          }}
          className={inputClassName}
          rows={rows}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          short={short}
          Icon={Icon}
        />
      )}
      {!writeNew && (
        <Select
          id={id}
          options={options}
          selected={selectValue}
          onChange={(v) => {
            setSelectValue(v)
          }}
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          invalid={invalid}
          className={inputClassName}
          required={required}
          autoFocus={autoFocus}
          short={short}
        />
      )}

      {options.length > 0 && (
        <div className='px-2'>
          <span className='mr-2 text-sm'>eller</span>
          <button
            onClick={() => {
              setWriteNew(!writeNew)
              onChange('')
            }}
            className='mb-1 mt-2 rounded bg-gray-100 py-1 px-3 text-sm text-gray-600 hover:bg-gray-200'
          >
            {!writeNew ? (
              <span className='flex flex-row items-center'>
                <Edit2Icon className='mr-2 h-3 w-3' /> Indtast ny {entity}
              </span>
            ) : (
              <span className='flex flex-row items-center'>
                <PointerIcon className='mr-2 h-3 w-3' /> Vælg eksisterende{' '}
                {entity}
              </span>
            )}
          </button>
        </div>
      )}
      {help && <span className=' block text-sm text-gray-500'>{help}</span>}
      {error && <span className=' block text-sm text-red-600'>{error}</span>}
    </div>
  )
}
