import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const VerifiedIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Verified-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M12 3a3.6 3.6 0 00-3.05 1.68 3.6 3.6 0 00-.9-.1 3.6 3.6 0 00-2.42 1.06 3.6 3.6 0 00-.94 3.32A3.6 3.6 0 003 12a3.6 3.6 0 001.69 3.05 3.6 3.6 0 00.95 3.32 3.6 3.6 0 003.35.96A3.6 3.6 0 0012 21a3.6 3.6 0 003.04-1.67 3.6 3.6 0 004.3-4.3A3.6 3.6 0 0021 12a3.6 3.6 0 00-1.67-3.04v0a3.6 3.6 0 00-4.3-4.3A3.6 3.6 0 0012 3z'></path>
      <path d='M15 10l-4 4'></path>
      <path d='M9 12l2 2'></path>
    </Icon>
  )
}

export default VerifiedIcon
