import React, { Fragment, ReactNode } from 'react'
import { Loading } from '../../loading'
import { PageHeading, PageHeadingProps } from './pageHeading'

export interface PageProps {
  headingProps: PageHeadingProps
  children: ReactNode
  className?: string
  loading?: { isLoading: boolean; title: string; text?: string }
}

export const Page = ({
  children,
  headingProps,
  className = '',
  loading
}: PageProps) => {
  return (
    <div className={`max-h-full min-h-full w-full bg-white ${className}`}>
      {loading?.isLoading && <Loading {...loading} />}
      {!loading?.isLoading && (
        <Fragment>
          {/* HEADER */}
          <PageHeading {...headingProps} />
          {/* CONTENT */}
          <main>{children}</main>
        </Fragment>
      )}
    </div>
  )
}
