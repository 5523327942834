import React, {
  Component,
  FunctionComponent,
  forwardRef,
  useState
} from 'react'
import cl from 'classnames'
import { EyeIcon, EyeOffIcon, XIcon } from '../../../..'

export interface InputProps {
  value: string | number
  onChange: (value: any) => void
  id: string
  placeholder?: string
  type?: 'text' | 'password'
  className?: string
  outerClassName?: string
  invalid?: boolean
  disabled?: boolean
  rows?: number
  autoFocus?: boolean
  autoComplete?: TAutoComplete
  short?: boolean
  Icon?: typeof Component | FunctionComponent
  clearable?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      className = '',
      outerClassName = '',
      id,
      placeholder,
      type = 'text',
      invalid = false,
      disabled = false,
      rows = 1,
      autoFocus = false,
      autoComplete = 'on',
      short = false,
      Icon,
      clearable = false
    },
    ref
  ) => {
    const [showPwd, setShowPwd] = useState<boolean>(false)

    const styles = cl(
      `border border-gray-300 rounded py-2 px-4 bg-white transition text-sm 
    focus:ring-blue-500 focus:ring-1 focus:outline-none
    focus:border-blue-500 active:ring-blue-500 active:ring-1 active:outline-none
    active:border-blue-500 w-full resize-none w-full relative z-0`,
      {
        ['border-red-500']: invalid,
        ['text-opacity-10 bg-gray-200 cursor-not-allowed']: disabled,
        [`md:w-96`]: !short,
        [`md:w-72`]: short
      },
      className
    )

    const sideIconStyles =
      'absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2 transform text-gray-600 hover:text-gray-700'

    if (rows === 1) {
      return (
        <div className={`relative ${outerClassName}`}>
          <input
            ref={ref}
            className={styles}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            type={type === 'password' ? (showPwd ? 'text' : 'password') : type}
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
          />
          {type === 'password' && showPwd && (
            <span className='h-full' onClick={() => setShowPwd(false)}>
              <EyeIcon className={`${sideIconStyles} cursor-pointer`} />
            </span>
          )}
          {type === 'password' && !showPwd && (
            <span className='h-full' onClick={() => setShowPwd(true)}>
              <EyeOffIcon className={`${sideIconStyles} cursor-pointer`} />
            </span>
          )}
          {Icon &&
            (!clearable || value.toString().length === 0) &&
            type !== 'password' && (
              <span className='h-full'>
                <Icon className={sideIconStyles} />
              </span>
            )}

          {clearable && type !== 'password' && value.toString().length > 0 && (
            <span className='h-full' onClick={() => onChange('')}>
              <XIcon className={`${sideIconStyles} cursor-pointer`} />
            </span>
          )}
        </div>
      )
    } else {
      return (
        <textarea
          className={styles}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          id={id}
          disabled={disabled}
          rows={rows}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
        />
      )
    }
  }
)

export default Input

type TAutoComplete =
  | 'on'
  | 'off'
  | 'name'
  | 'given-name'
  | 'additional-name'
  | 'family-name'
  | 'email'
  | 'new-password'
  | 'current-password'
  | 'one-time-code'
  | 'organization'
  | 'street-address'
  | 'country'
  | 'language'
  | 'tel'
  | 'url'
