import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ZapOffIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'ZapOff-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='12.41 6.75 13 2 10.57 4.92'></polyline>
      <polyline points='18.57 12.91 21 10 15.66 10'></polyline>
      <polyline points='8 8 3 14 12 14 11 22 16 16'></polyline>
      <line x1='1' y1='1' x2='23' y2='23'></line>
    </Icon>
  )
}

export default ZapOffIcon
