import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ArrowLeftRightIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'ArrowLeftRight-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='17 11 21 7 17 3'></polyline>
      <line x1='21' y1='7' x2='9' y2='7'></line>
      <polyline points='7 21 3 17 7 13'></polyline>
      <line x1='15' y1='17' x2='3' y2='17'></line>
    </Icon>
  )
}

export default ArrowLeftRightIcon
