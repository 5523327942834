import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const EyeOffIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'EyeOff-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24'></path>
      <line x1='1' y1='1' x2='23' y2='23'></line>
    </Icon>
  )
}

export default EyeOffIcon
