import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const LogInIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'LogIn-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M15 3h4a2 2 0 012 2v14a2 2 0 01-2 2h-4'></path>
      <polyline points='10 17 15 12 10 7'></polyline>
      <line x1='15' y1='12' x2='3' y2='12'></line>
    </Icon>
  )
}

export default LogInIcon
