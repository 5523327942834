import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const BuildingIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Building-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <rect x='4' y='2' width='16' height='20' rx='2' ry='2'></rect>
      <path d='M9 22v-4h6v4'></path>
      <path d='M8 6h.01'></path>
      <path d='M16 6h.01'></path>
      <path d='M12 6h.01'></path>
      <path d='M12 10h.01'></path>
      <path d='M12 14h.01'></path>
      <path d='M16 10h.01'></path>
      <path d='M16 14h.01'></path>
      <path d='M8 10h.01'></path>
      <path d='M8 14h.01'></path>
    </Icon>
  )
}

export default BuildingIcon
