import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const CheckIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Check-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='20 6 9 17 4 12'></polyline>
    </Icon>
  )
}

export default CheckIcon
