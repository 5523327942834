import React, { Fragment, FunctionComponent, ReactChild } from 'react'
import { CheckCircleIcon } from '../../../..'
import FieldLabel from '../../../label/label'
import Select, { SelectProps } from './select'

export interface SelectFieldsetProps extends SelectProps {
  label: string
  help?: string | ReactChild
  error?: string | ReactChild
  inputClassName?: string
  valid?: boolean
  short?: boolean
}

export const SelectFieldset: FunctionComponent<SelectFieldsetProps> = ({
  label,
  help = null,
  error = null,
  selected,
  onChange,
  className = '',
  inputClassName = '',
  id,
  disabled = false,
  invalid = false,
  placeholder,
  options,
  required,
  autoFocus,
  valid = false,
  short = false
}) => {
  return (
    <div className={`w-full space-y-1.5 md:w-min ${className}`}>
      <FieldLabel
        htmlFor={id}
        className='flex w-full items-center justify-between'
      >
        <span className='flex items-center'>
          {label}{' '}
          {valid && <CheckCircleIcon className='ml-1 h-3 w-3 text-blue-500' />}
        </span>{' '}
        {required && (
          <Fragment>
            <span className=' hidden font-normal text-gray-400 sm:block'>
              Påkrævet
            </span>
            <span className='text-gray-400 sm:hidden'>*</span>
          </Fragment>
        )}
      </FieldLabel>
      <Select
        id={id}
        placeholder={placeholder ? placeholder : label}
        disabled={disabled}
        invalid={invalid}
        selected={selected}
        options={options}
        onChange={onChange}
        className={inputClassName}
        required={required}
        autoFocus={autoFocus}
        short={short}
      />
      {help && <span className='block text-sm text-gray-500'>{help}</span>}
      {error && (
        <span className='ml-2 block text-sm text-red-600'>{error}</span>
      )}
    </div>
  )
}

export default SelectFieldset
