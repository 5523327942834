import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const Edit2Icon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Edit2-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
    </Icon>
  )
}

export default Edit2Icon
