import * as React from 'react'
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { Portal } from 'react-portal'
import { Button } from '../button'
import { ButtonProps } from '../button'

export type Position =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'

export type ZIndex = 10 | 20 | 30 | 40 | 50

export interface DropdownMenuProps {
  children: React.ReactNode
  buttonProps?: Omit<ButtonProps, 'onClick' | 'children'>
  position?: Position
  panelChildren: React.ReactNode
  panelZIndex?: ZIndex
  className?: string
  offsetX?: number
  offsetY?: number
}

export const PopoverDropdown = ({
  position = 'bottom-end',
  children,
  className,
  buttonProps = {},
  panelChildren,
  panelZIndex = 10,
  offsetX = 0,
  offsetY = 8
}: DropdownMenuProps) => {
  const popperElRef = React.useRef() as any
  const [targetElement, setTargetElement] =
    React.useState<HTMLDivElement>() as any
  const [popperElement, setPopperElement] = React.useState(null) as any
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: position,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetX, offsetY]
        }
      }
    ]
  })

  return (
    <Popover>
      {({ open }) => (
        <div>
          <div ref={setTargetElement} className='w-min rounded-md shadow-sm'>
            <Popover.Button as={Button} {...buttonProps}>
              {children}
            </Popover.Button>
          </div>

          <Portal>
            <Popover.Panel
              ref={popperElRef}
              className={getZIndexClass(panelZIndex)}
              style={styles.popper}
              {...attributes.popper}
            >
              <Transition
                show={open}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <div
                  className={`outline-none w-min origin-top-right space-y-6 rounded-md border border-gray-300 bg-white p-4 shadow-lg ${className}`}
                >
                  {panelChildren}
                </div>
              </Transition>
            </Popover.Panel>
          </Portal>
        </div>
      )}
    </Popover>
  )
}

function getZIndexClass(z: ZIndex): string {
  switch (z) {
    case 10:
      return 'z-10'
    case 20:
      return 'z-20'
    case 30:
      return 'z-30'
    case 40:
      return 'z-40'
    case 50:
      return 'z-50'
  }
}
