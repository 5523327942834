import React, { FunctionComponent, ReactNode } from 'react'
import cl from 'classnames'

export interface CheckboxProps {
  label: ReactNode
  ownValue?: any
  value: any
  onChange: (value: any) => void
  className?: string
  disabled?: boolean
  autoFocus?: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  onChange,
  label,
  className = '',
  value,
  autoFocus = false,
  disabled = false
}) => {
  const labelStyles = cl([
    `select-none font-semibol cursor-pointer flex 
  flex-row items-center font-medium text-gray-700 text-sm hover:text-gray-800`,
    {
      ['text-opacity-50 cursor-not-allowed']: disabled
    },
    className
  ])

  const checkboxStyles = cl([
    `border rounded border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-3 
     cursor-pointer`
  ])

  return (
    <label className={labelStyles}>
      <input
        type='checkbox'
        checked={value}
        onChange={(e) => onChange(e.currentTarget.checked)}
        className='absolute h-4 w-4 cursor-pointer opacity-0'
        autoFocus={autoFocus}
        disabled={disabled}
      />
      <div className={checkboxStyles}>
        <svg
          className='pointer-events-none hidden h-3 w-3 fill-current text-blue-600'
          version='1.1'
          viewBox='0 0 17 12'
          xmlns='http://www.w3.org/2000/svg'
          strokeWidth='4.5px'
        >
          <g fill='none' fillRule='evenodd'>
            <g transform='translate(-9 -11)' fill='#1F73F1' fillRule='nonzero'>
              <path d='m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z' />
            </g>
          </g>
        </svg>
      </div>
      {label}
    </label>
  )
}

export default Checkbox
