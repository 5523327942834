import React, { ReactNode, FunctionComponent as FC } from 'react'
import cl from 'classnames'

export interface IBadge {
  children: ReactNode
  className?: string
  type?: 'green' | 'blue' | 'red' | 'yellow' | 'gray'
  inverse?: boolean
}

export const Badge: FC<IBadge> = ({
  children,
  className = '',
  type = 'blue',
  inverse = false
}) => {
  const badgeStyles = cl([
    `py-1 px-2 text-white text-center w-max tracking-widest leading-none
    font-bold uppercase text-xs rounded`,
    {
      [`bg-blue-500`]: type === 'blue' && !inverse,
      [`text-blue-500`]: type === 'blue' && inverse,
      [`bg-green-500`]: type === 'green' && !inverse,
      [`text-green-500`]: type === 'green' && inverse,
      [`bg-red-500`]: type === 'red' && !inverse,
      [`text-red-500`]: type === 'red' && inverse,
      [`bg-yellow-500`]: type === 'yellow' && !inverse,
      [`text-yellow-500`]: type === 'yellow' && inverse,
      [`bg-gray-400`]: type === 'gray' && !inverse,
      [`text-gray-400`]: type === 'gray' && inverse,
      [`bg-white`]: inverse
    },
    className
  ])
  return <div className={badgeStyles}>{children}</div>
}

export default Badge
