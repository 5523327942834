import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const EyeIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Eye-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
      <circle cx='12' cy='12' r='3'></circle>
    </Icon>
  )
}

export default EyeIcon
