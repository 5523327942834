import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const Trash2Icon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Trash2-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <polyline points='3 6 5 6 21 6'></polyline>
      <path d='M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2'></path>
      <line x1='10' y1='11' x2='10' y2='17'></line>
      <line x1='14' y1='11' x2='14' y2='17'></line>
    </Icon>
  )
}

export default Trash2Icon
