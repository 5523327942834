import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ExpandIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Expand-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M21 21l-6-6m6 6v-4.8m0 4.8h-4.8'></path>
      <path d='M3 16.2V21m0 0h4.8M3 21l6-6'></path>
      <path d='M21 7.8V3m0 0h-4.8M21 3l-6 6'></path>
      <path d='M3 7.8V3m0 0h4.8M3 3l6 6'></path>
    </Icon>
  )
}

export default ExpandIcon
