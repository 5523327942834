import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const UserIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Lynikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2'></path>
      <circle cx='12' cy='7' r='4'></circle>
    </Icon>
  )
}

export default UserIcon
