import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const EquipmentIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Equipment-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M6 10H4a2 2 0 01-2-2V4a2 2 0 012-2h16a2 2 0 012 2v4a2 2 0 01-2 2h-2'></path>
      <path d='M6 14H4a2 2 0 00-2 2v4a2 2 0 002 2h16a2 2 0 002-2v-4a2 2 0 00-2-2h-2'></path>
      <path d='M6 6h.01'></path>
      <path d='M6 18h.01'></path>
      <path d='M13 6l-4 6h6l-4 6'></path>
    </Icon>
  )
}

export default EquipmentIcon
