import {
  format,
  getDate,
  getDay,
  getMonth,
  getYear,
  isLastDayOfMonth,
  isSameDay,
  setHours
} from 'date-fns'
import { addDays, isAfter, isToday, subDays } from 'date-fns/esm'
import da from 'date-fns/esm/locale/da/index.js'
import React, { useState } from 'react'
import CalendarIcon from '../../../icons/Calendar'
import ChevronLeftIcon from '../../../icons/ChevronLeft'
import ChevronRightIcon from '../../../icons/ChevronRight'
import { ChevronDownIcon, ChevronUpIcon } from '../../../..'
import { classNames } from '../../../../helpers/classnames'

export interface DatePickerProps {
  id: string
  value: Date | undefined
  onChange: (value: any) => void
  disabled?: boolean

  options?: {
    onlyFuture?: boolean
    onlyWeekdays?: boolean
    disabledFunc?: (d: Date) => boolean
  }
}

export const DatePicker = ({
  value,
  onChange,
  options,
  disabled = false,
  id
}: DatePickerProps) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const [shownMonth, setShownMonth] = useState<number>(
    value ? getMonth(value) : getMonth(new Date())
  )

  const [shownYear, setShownYear] = useState<number>(
    value ? getYear(value) : getYear(new Date())
  )

  const increment = () => {
    if (shownMonth <= 10) {
      setShownMonth(shownMonth + 1)
    } else {
      setShownMonth(0)
      setShownYear(shownYear + 1)
    }
  }

  const decrement = () => {
    if (shownMonth >= 1) {
      setShownMonth(shownMonth - 1)
    } else {
      setShownMonth(11)
      setShownYear(shownYear - 1)
    }
  }

  return (
    <div
      className={`w-full rounded  md:w-96 ${
        disabled ? '' : 'hover:border-blue-500'
      }`}
      id={id}
    >
      {/* CHOSEN VALUE */}
      <div
        className={`flex flex-row items-center rounded border border-gray-300 py-2 px-4 text-sm  
        ${
          disabled
            ? 'cursor-not-allowed bg-gray-200 text-opacity-10'
            : 'cursor-pointer'
        }
         ${showCalendar ? 'border-b' : ''}`}
        onClick={() => {
          if (!disabled) {
            setShowCalendar(!showCalendar)
          }
        }}
      >
        <CalendarIcon className='mr-2 h-4 w-4 text-gray-400' />
        {value ? (
          capitalise(format(value, `EEEE 'd.' d. MMM y`, { locale: da }))
        ) : (
          <span className='text-gray-400'>Vælg en dato</span>
        )}
        {!showCalendar && <ChevronUpIcon className={`ml-auto h-4 w-4`} />}
        {showCalendar && <ChevronDownIcon className={`ml-auto h-4 w-4`} />}
      </div>

      {/* CONTROLS */}
      {showCalendar && (
        <div className='mt-1 flex flex-row items-center justify-center rounded-t border border-b-0 py-1 px-2 text-sm'>
          <div className='flex flex-1 flex-row items-center justify-between py-1 px-2 lg:px-0'>
            <span onClick={decrement} className='cursor-pointer p-2'>
              <ChevronLeftIcon className='h-4 w-4 text-gray-600' />
            </span>
            <span>
              <span className='mr-1'>{monthToString(shownMonth)}</span>
              <span>{shownYear}</span>
            </span>
            <span onClick={increment} className='cursor-pointer p-2'>
              <ChevronRightIcon className='h-4 w-4 text-gray-600' />
            </span>
          </div>
        </div>
      )}
      {/* DAYS */}
      {showCalendar && (
        <div className='grid grid-cols-7 rounded-b border border-t-0 p-0.5 text-xs shadow-lg lg:p-2 lg:pt-1'>
          {mapDays()}
          {getDatesWithMetaData(
            createMonthArray(shownMonth, shownYear),
            shownMonth,
            options
          ).map(({ date, include, disabled }, idx) => {
            const isSelected = value && isSameDay(date, value)
            const isToday = isSameDay(date, new Date())

            const dayStyle = classNames(
              include ? '' : 'invisible',
              disabled ? 'text-gray-200 cursor-not-allowed' : 'cursor-pointer',
              isSelected ? 'text-white cursor-default' : '',
              !isSelected && isToday ? 'text-blue-600' : '',
              isSelected && isToday ? 'bg-blue-600' : '',
              isSelected && !isToday ? 'bg-blue-900' : '',
              !isSelected ? 'hover:bg-blue-100' : '',
              isSelected || isToday ? 'font-semibold' : '',
              'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
            )

            return (
              <div
                key={date.getTime()}
                className={classNames(
                  idx > 6 ? 'border-t border-gray-200' : '',
                  'py-2'
                )}
              >
                <div
                  onClick={
                    disabled
                      ? undefined
                      : () => {
                          onChange(date)
                          setShowCalendar(false)
                        }
                  }
                  className={dayStyle}
                >
                  {getDate(date)}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

function mapDays() {
  return ['M', 'T', 'O', 'T', 'F', 'L', 'S'].map((d) => (
    <div key={d} className='py-1 text-center text-xs text-gray-500'>
      {d}
    </div>
  ))
}

function createMonthArray(month: number, year: number): Date[] {
  const firstDayOfMonth = new Date(year, month, 1)
  const firstDay = getFirstDayOfMonthView(firstDayOfMonth)

  const monthArray: Date[] = [firstDay]
  let lastDayHasPassed = false
  while (!lastDayHasPassed) {
    const nextDay = addDays(getLastItem(monthArray), 1)
    monthArray.push(nextDay)

    if (isLastDayOfMonth(nextDay) && getMonth(nextDay) === month)
      lastDayHasPassed = true
  }

  return monthArray
}

function getFirstDayOfMonthView(firstDay: Date) {
  let day = firstDay
  // while week day is not monday
  while (getDay(day) !== 1) {
    day = subDays(day, 1)
  }

  return day
}

function getLastItem(arr: Date[]): Date {
  return arr[arr.length - 1]
}

function getDatesWithMetaData(
  arr: Date[],
  month: number,
  options?: {
    onlyFuture?: boolean
    onlyWeekdays?: boolean
    disabledFunc?: (d: Date) => boolean
  }
): { date: Date; include: boolean; disabled: boolean }[] {
  return arr.map((d) => ({
    date: d,
    include: getMonth(d) === month,
    disabled: disabledFilter(d, options)
  }))
}

function disabledFilter(
  date: Date,
  options?: {
    onlyFuture?: boolean
    onlyWeekdays?: boolean
    disabledFunc?: (d: Date) => boolean
  }
): boolean {
  if (!options) return false
  const fromFunc = options.disabledFunc ? options.disabledFunc(date) : false
  const fromOnlyFuture = options.onlyFuture
    ? isAfter(setHours(new Date(), 1), date) && !isToday(date)
    : false
  const fromOnlyWeekdays = options.onlyWeekdays
    ? [0, 6].includes(getDay(date))
    : false
  return fromFunc || fromOnlyFuture || fromOnlyWeekdays
}

function monthToString(month: number): string {
  const months = [
    'Januar',
    'Februar',
    'Marts',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'December'
  ]
  return months[month] ? months[month] : 'Ugyldig måned'
}

function capitalise(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default DatePicker
