import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const MailIcon: FC<IIcon> = ({ className = '', description, strokeWidth }) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Mail-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'></path>
      <polyline points='22,6 12,13 2,6'></polyline>
    </Icon>
  )
}

export default MailIcon
