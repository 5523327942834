import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const CheckCircleIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'CheckCircle-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
      <polyline points='22 4 12 14.01 9 11.01'></polyline>
    </Icon>
  )
}

export default CheckCircleIcon
