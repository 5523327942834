import React, { ReactNode } from 'react'
import { Link, NavLink } from 'react-router-dom'
import ChevronRightIcon from '../../icons/ChevronRight'
import { PassableReactComponent } from '../../../typings'
import { ActionGroup, ActionGroupProps } from './actionGroup'

export interface PageHeadingProps {
  title: ReactNode
  /**
   * Do not use subtitle alongside the `details`-prop
   */
  subtitle?: ReactNode
  breadcrumbs?: { text: ReactNode; href: string }[]
  details?: { text: ReactNode; icon?: PassableReactComponent; href?: string }[]
  /**
   * Props to pass to a `<ActionGroup />
   */
  actionGroupProps?: ActionGroupProps
  /**
   * Free-form ReactNode to render after ActionGroup (if present)
   */
  renderActions?: ReactNode
}

export const PageHeading = ({
  title,
  subtitle,
  breadcrumbs,
  details,
  actionGroupProps,
  renderActions
}: PageHeadingProps) => (
  <div className='p-4 sm:p-8 lg:flex lg:items-center lg:justify-between'>
    <div className='min-w-0 flex-1'>
      {/* BREADCRUMBS */}
      {breadcrumbs && (
        <nav className='hidden sm:flex' aria-label='Breadcrumb'>
          <ol role='list' className='flex items-center space-x-3'>
            {breadcrumbs.map((bc, idx, bcs) => {
              const isFirst = idx === 0
              const isLast = idx === bcs.length - 1
              if (isFirst) {
                return (
                  <li key={idx}>
                    <div className='flex'>
                      <Link
                        to={bc.href}
                        className='text-sm font-medium text-gray-500 hover:text-gray-700'
                      >
                        {bc.text}
                      </Link>
                    </div>
                  </li>
                )
              } else if (isLast) {
                return (
                  <li key={idx}>
                    <div className='flex items-center'>
                      <ChevronRightIcon
                        className='h-5 w-5 flex-shrink-0 text-gray-300'
                        aria-hidden='true'
                        strokeWidth={1.5}
                      />
                      <p className='ml-3 text-sm font-medium text-gray-400'>
                        {bc.text}
                      </p>
                    </div>
                  </li>
                )
              } else {
                return (
                  <li>
                    <div className='flex items-center'>
                      <ChevronRightIcon
                        className='h-5 w-5 flex-shrink-0 text-gray-300'
                        aria-hidden='true'
                        strokeWidth={1.5}
                      />
                      <Link
                        to={bc.href}
                        className='ml-4 text-sm font-medium text-gray-400 hover:text-gray-700'
                      >
                        {bc.text}
                      </Link>
                    </div>
                  </li>
                )
              }
            })}
          </ol>
        </nav>
      )}

      {/* TITLE */}
      <h1
        className={`${
          breadcrumbs && 'sm:mt-3'
        } text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl`}
      >
        {title}
      </h1>
      {subtitle && <h2 className='mt-1 text-gray-500'>{subtitle}</h2>}

      {/* DETAILS */}
      {details && (
        <div className='mt-1.5 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6'>
          {details.map((dt, idx) =>
            dt.href ? (
              <NavLink
                to={dt.href}
                key={idx}
                className='mt-2 flex items-center text-sm font-medium text-blue-900 hover:underline'
              >
                {dt.icon ? (
                  <dt.icon
                    className='mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                    strokeWidth={2}
                  />
                ) : null}
                {dt.text}
              </NavLink>
            ) : (
              <div
                key={idx}
                className='mt-2 flex items-center text-sm text-gray-500'
              >
                {dt.icon ? (
                  <dt.icon
                    className='mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                    strokeWidth={2}
                  />
                ) : null}
                {dt.text}
              </div>
            )
          )}
        </div>
      )}
    </div>

    {/* ACTIONS */}
    {actionGroupProps && (
      <div className='mt-5 flex lg:mt-0 lg:ml-4'>
        {<ActionGroup {...actionGroupProps} />}
      </div>
    )}

    {/* RENDERED ACTIONS */}
    {renderActions && (
      <div className='mt-5 flex lg:mt-0 lg:ml-4'>{renderActions}</div>
    )}
  </div>
)
