import React, { ReactNode } from 'react'

export interface FormSectionprops {
  heading: string
  info?: ReactNode
  first?: boolean
  children: ReactNode
}

export const FormSection = ({
  heading,
  info = null,
  children,
  first = false
}: FormSectionprops) => {
  return (
    <div
      className={`flex flex-col ${
        first ? '' : 'border-t border-gray-200 pt-2'
      } md:flex-row`}
    >
      <div className='w-full p-2 md:w-72 md:p-4 md:pr-8'>
        <h2 className='text-lg font-medium leading-6 text-gray-900'>
          {heading}
        </h2>
        <div className='mt-1 hidden space-y-3 text-sm text-gray-500 md:flex md:flex-col'>
          {info}
        </div>
      </div>
      <div className=' space-y-8 p-2 md:p-4'>{children}</div>
    </div>
  )
}
