import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const ExternalLinkIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'ExternalLink-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6'></path>
      <polyline points='15 3 21 3 21 9'></polyline>
      <line x1='10' y1='14' x2='21' y2='3'></line>
    </Icon>
  )
}

export default ExternalLinkIcon
