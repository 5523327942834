import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const HammerIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'Hammer-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M15 12l-8.5 8.5c-.83.83-2.17.83-3 0 0 0 0 0 0 0a2.12 2.12 0 010-3L12 9'></path>
      <path d='M17.64 15L22 10.64'></path>
      <path d='M20.91 11.7l-1.25-1.25c-.6-.6-.93-1.4-.93-2.25v-.86L16.01 4.6a5.56 5.56 0 00-3.94-1.64H9l.92.82A6.18 6.18 0 0112 8.4v1.56l2 2h2.47l2.26 1.91'></path>
    </Icon>
  )
}

export default HammerIcon
