import React, { Fragment, FunctionComponent, ReactChild } from 'react'
import { CheckCircleIcon } from '../../../..'
import FieldLabel from '../../../label/label'
import DatePicker, { DatePickerProps } from './datepicker'

export interface DatePickerFieldset extends DatePickerProps {
  label: string
  help?: string | ReactChild
  error?: string | ReactChild
  className?: string
  valid?: boolean
  required?: boolean
}

export const DatePickerFieldset: FunctionComponent<DatePickerFieldset> = ({
  label,
  help = null,
  error = null,
  value,
  onChange,
  className = '',
  id,
  options,
  valid,
  required
}) => {
  return (
    <div className={`w-full space-y-1.5 ${className}`}>
      <FieldLabel
        htmlFor={id}
        className='flex w-full items-center justify-between'
      >
        <span className='flex items-center'>
          {label}{' '}
          {valid && <CheckCircleIcon className='ml-1 h-3 w-3 text-blue-500' />}
        </span>{' '}
        {required && (
          <Fragment>
            <span className=' hidden font-normal text-gray-400 sm:block'>
              Påkrævet
            </span>
            <span className='text-gray-400 sm:hidden'>*</span>
          </Fragment>
        )}
      </FieldLabel>
      {help && (
        <span className='mb-2 ml-2 -mt-1 block text-sm text-gray-600 dark:text-gray-400'>
          {help}
        </span>
      )}
      <DatePicker id={id} value={value} onChange={onChange} options={options} />
      <span className='ml-2 block text-sm text-red-600 dark:text-red-400'>
        {error}
      </span>
    </div>
  )
}

export default DatePickerFieldset
