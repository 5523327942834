import React, { FunctionComponent } from 'react'

export interface FormProps {
  children: React.ReactNode
  className?: string
  onSubmit: () => void
}

export const Form: FunctionComponent<FormProps> = ({
  children,
  className = '',
  onSubmit
}) => {
  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      {React.Children.map(children, (child) => (
        <span className='my-4 block'>{child}</span>
      ))}
    </form>
  )
}

export default Form
