import React, { FunctionComponent as FC } from 'react'
import Icon, { IIcon } from './Icon'

const UserPlusIcon: FC<IIcon> = ({
  className = '',
  description,
  strokeWidth
}) => {
  return (
    <Icon
      className={className}
      description={description ? description : 'UserPlus-ikon'}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    >
      <path d='M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2'></path>
      <circle cx='8.5' cy='7' r='4'></circle>
      <line x1='20' y1='8' x2='20' y2='14'></line>
      <line x1='23' y1='11' x2='17' y2='11'></line>
    </Icon>
  )
}

export default UserPlusIcon
